<template>
  <div class="relative bg-blue text-white">
    <img
      src="@/assets/images/get-in-touch-bg.svg"
      alt=""
      class="absolute bottom-0 object-contain"
    />

    <div class="container mx-auto px-4">
      <div class="relative z-10 h-screen py-20 px-4 2lx:px-0 lg:py-24 flex">
        <div
          class="w-1/4 flex flex-col justify-center items-start
                 pt-1 pr-4 lg:pr-12"
        >
          <div class="max-w-sm">
            <h1
              class="mb-4 text-lg lg:text-2xl 2xl:text-5xl font-light get-in-touch__title"
              v-html="contact.interest.title"
            />

            <div class="max-h-30vh overflow-auto custom-scrollbar">
              <div
                class="mb-4 text-sm 2xl:text-lg"
                v-html="contact.interest.text"
              />
            </div>
          </div>
        </div>

        <div class="w-3/4 2xl:w-2/3 flex flex-col justify-center">
          <div class="-m-2 mb-5 flex flex-wrap">
            <div
              v-for="solution in solutionsList"
              :key="solution.uid"
              class="p-2 w-1/4"
            >
              <v-solution-card
                v-model="solution.selected"
                :solution="solution"
                class="min-h-full"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <footer class="fixed z-40 left-0 bottom-0 w-full px-8 py-8">
      <div class="flex items-center justify-between">
        <div class="min-w-30vw">
          <button
            class="inline-flex items-center text-base 2xl:text-lg text-white
                   cursor-pointer transition-colors hover:text-green"
            @click="goBack"
          >
            <i
              class="inline-flex items-center justify-center border-2 border-white rounded-full
                    w-8 2xl:w-12 h-8 2xl:h-12 p-2
                    icon-arrow-left text-white mr-2"
            />
            Back
          </button>
        </div>

        <button
          type="submit"
          class="mx-3 border-2 border-white rounded-full py-2 lg:py-6 px-5 lg:px-12 bg-blue
          text-sm 2xl:text-xl font-bold text-white
          transition-colors
          hover:bg-white hover:text-blue
          focus:outline-none focus:bg-white focus:text-black"
          @click="submitHandler"
        >
          Confirm and proceed
        </button>

        <div class="min-w-30vw"></div>
      </div>
    </footer>

    <transition name="fade-left">
      <v-contact-info
        v-if="showContactInfo"
        @prev="showContactInfo = false"
        @next="showThankYou = true"
      />
    </transition>

    <transition name="fade-up">
      <v-thank-you
        v-if="showThankYou"
        @prev="showThankYou = false"
      />
    </transition>
  </div>
</template>

<script>
import {
  SolutionCard,
  ContactInfo,
  ThankYou,
} from '@/components';

import { mapGetters, mapActions } from 'vuex';
import { getters, actions } from '@/store';

export default {
  name: 'GetInTouchPage',
  components: {
    VSolutionCard: SolutionCard,
    VContactInfo: ContactInfo,
    VThankYou: ThankYou,
  },
  watch: {
    solutionsList: {
      handler(newVal) {
        const selectedIds = newVal.filter((s) => s.selected).map((s) => s.uid);
        this[actions.contactForm.selectMany](selectedIds);
      },
      immediate: false,
      deep: true,
    },
  },
  data() {
    return {
      showContactInfo: false,
      showThankYou: false,
      solutionsList: [],
      hideConcourseOverlay: false,
    };
  },
  mounted() {
    this.solutionsList = this.solutions.map((solution) => ({
      ...solution,
      selected: !!this.selected.find((id) => id === solution.uid),
    }));
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.name === 'concourse-view.index') {
        // eslint-disable-next-line no-param-reassign
        vm.hideConcourseOverlay = true;
      }
    });
  },
  computed: {
    ...mapGetters([
      getters.content.solutions,
      getters.content.contact,
      getters.contactForm.selected,
    ]),
  },
  methods: {
    ...mapActions([
      actions.contactForm.selectMany,
    ]),
    openStadium() {
      this.$router.push({
        name: 'stadium.index',
      });
    },
    submitHandler() {
      this.showContactInfo = true;
    },
    goBack() {
      if (this.hideConcourseOverlay) {
        this.$router.push({
          name: 'concourse-view.index',
          params: {
            hideOverlay: true,
          },
        });
      } else {
        this.$router.go(-1);
      }
    },
  },
};
</script>

<style lang="scss">
.get-in-touch__title {
  strong {
    font-weight: 500;
  }
}
</style>
